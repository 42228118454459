import {sendDownloadLink} from "@services/website/downloadLink";
import {useTranslation} from "@i18n/client";
import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import IntlTelInput, {CountryData} from "react-intl-tel-input";
import {NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY} from "src/publicEnv";

import {trackToPatient} from "src/utils/googleAnalytics";
import styles from "./TextMeApp.module.scss";
import {useIsMobile} from "src/utils/isMobile";
import {LazyComp} from "../_common/LazyComp";

type Props = {
  downloadDeepLink?: string;
  practiceSlug: string;
  action?: string;
  gA: any;
  inputStyle?: React.CSSProperties;
};

enum SubmitStatus {
  WAITING,
  SENT,
  ERROR,
}

const TextmeApp: React.FC<Props> = ({
  downloadDeepLink,
  practiceSlug,
  gA,
  action = "Download app",
  inputStyle = {},
}) => {
  const isMobile = useIsMobile();

  const i18n = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.WAITING);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const getPhone = () =>
    document.getElementById("phone-number-input")?.getElementsByClassName("phone")[0];

  useEffect(() => {
    const phone = getPhone();
    if (phone) {
      phone.setAttribute("required", "true");
    }
  }, []);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const captcha = recaptchaRef.current ? await recaptchaRef.current.executeAsync() : null;

    sendDownloadLink({phone: phoneNumber, practiceSlug, captcha})
      .then(() => {
        setSubmitStatus(SubmitStatus.SENT);
        trackToPatient({
          ga: {
            category: "Download app",
            action,
            label: "download",
          },
        });
      })
      .catch(() => {
        setSubmitStatus(SubmitStatus.ERROR);
      });
  };

  const handlePhoneNumberChange = (
    status: boolean,
    _value: string,
    _countryData: CountryData,
    number: string,
  ) => {
    const phone = getPhone();
    if (status && phone) {
      phone.removeAttribute("pattern");
    } else if (phone) {
      phone.setAttribute("required", "true");
      phone.setAttribute("pattern", "abc");
    }
    setPhoneNumber(number);
  };

  return isMobile ? (
    <div className={styles.mobile}>
      <a href={downloadDeepLink} className={styles.downlaodBtn} onClick={() => gA && gA()}>
        {i18n.t("Get the Carbon Health App")}
      </a>
    </div>
  ) : (
    <div>
      {submitStatus === SubmitStatus.WAITING ? (
        <form id="phone-number-input" onSubmit={handleSubmit} className={styles.form}>
          {/* LazyComp is required to prevent hydration errors */}
          <LazyComp>
            <IntlTelInput
              autoPlaceholder={false}
              inputClassName="phone"
              defaultCountry="us"
              placeholder={i18n.t("Phone number")}
              format
              style={inputStyle}
              onPhoneNumberChange={handlePhoneNumberChange}
            />
          </LazyComp>

          <button className={`${styles.btn} btn btn-genoa`} type="submit">
            {i18n.t("Text Me the App")}
          </button>
          {NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY ? (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY}
              size="invisible"
            />
          ) : null}
        </form>
      ) : submitStatus === SubmitStatus.ERROR ? (
        <div>
          <div className="btn pen">{i18n.t("Something went wrong!")}</div>
          <button
            className="color-brand di brdn bg-inherit cp us font-cm"
            onClick={() => setSubmitStatus(SubmitStatus.WAITING)}
          >
            {i18n.t("Try again.")}
          </button>
        </div>
      ) : (
        <div>
          <div className="btn btn-genoa pen df aic">
            <span className="cIcon-check-rounded fs14 mr2 gray50 di" />
            {i18n.t("Text Sent Successfully!")}
          </div>
          <p className="mt4">
            {i18n.t("It didn't work?")}
            <button
              className="color-brand di brdn bg-inherit cp us font-cm"
              onClick={() => setSubmitStatus(SubmitStatus.WAITING)}
            >
              {i18n.t("Try again.")}
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

export default TextmeApp;
